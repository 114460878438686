import React from "react";
import Section from "./section";
import TemplateString from "./templateString";
import parseAndTransform from "./../helper/parseAndTransform"
import ScrollDown from "../../static/images/scroll_down.svg";

const Hero = ({image, data, imageLeft}) => {
    return(
        <Section>
            <div className="relative box-border pb-32 md:pb-10 2xl:pb-20">
                <header id="hero" style={(imageLeft) ? {flexDirection: "row-reverse"} : {} } className="flex justify-center items-center w-full max-w-screen-xl m-auto pt-20">
                    <div className="flex justify-center items-center flex-wrap md:flex-nowrap md:my-16 lg:mt-40 lg:mb-60">
                        <div className="h-full my-10">
                            <h2 style={{lineHeight: "1.25em"}} className="font-bold text-5xl text-center md:text-left my-10 ">
                                {parseAndTransform(data.header, "%").map((obj, index) =>{
                                    if(obj.template){
                                        return(
                                            <TemplateString
                                                text={obj.string}
                                                classname={"color-ecoGreen"}
                                                key={index}
                                            />
                                        )
                                    }
                                    else{
                                        return <span key={index}>{obj.string}</span>
                                    }
                                })}
                            </h2>
                            <p className="text-base lg:text-xl text-center md:text-left my-5">
                                {parseAndTransform(data.content, "%").map((obj, index) =>{
                                    if(obj.template){
                                        return(
                                            <TemplateString
                                                text={obj.string}
                                                classname={"font-bold"}
                                                key={index}
                                            />
                                        )
                                    }
                                    const string = obj.string.split(/\s|-/g);
                                    return string.map((str, i) => {
                                        if(/CO2[\W\S]?/.test(str)){
                                            return (
                                                    <span key={i}> CO<sub>2</sub>{(str.length > 3) ? str[str.length - 1] : ""}</span>
                                            )
                                        }
                                        if(i === 0){
                                            return <span key={i}>{str}</span>
                                        }
                                            return <span key={i}>{" " + str}</span>
                                    })
                                })}
                            </p>
                        </div>
                        <div className="h-full hidden md:block my-10">
                            <img alt="Illustration bei undraw.co" src={image}></img>
                        </div>
                    </div>
                </header>
                <div className="absolute bottom-0 w-full h-20 flex justify-center items center">
                    {/* TODO: muss zu "#view_counter" getauscht werden sobald View Counter aktiviert wird */}
                    <a href="#view_reduceEmission">
                        <div>
                            <p className="text-center text-xs tracking-widest my-2">SCROLL DOWN</p>
                            <img width="100" src={ScrollDown} alt="scroll down"></img>
                        </div>
                    </a>
                </div>
            </div>
        </Section>
    )
}

export default Hero;