import React, { useEffect, useState } from "react";
import Section from "./section";
import Video_webm from "../../static/video/ecoad_video.webm"
import Video_mp4 from "../../static/video/ecoad_video_.mp4"
import Video_poster from "../../static/images/ecoad_video_poster.jpg"

const VideoSection = () => {
    const [width, setWidth] = useState("400");
    const [height, setHeight] = useState("225");
    useEffect(() => {
        const isMobile = !window.matchMedia("(min-width: 1024px)").matches;
        if(!isMobile){
            setWidth("800");
            setHeight("450");
        }
    }, []);
    return(
        <Section>
            <div id="view_video" className="flex justify-center items-center w-full max-w-screen-xl m-auto py-72">
                <video className="md:max-w-2xl lg:max-w-5xl border border-gray-600"
                    controls
                    poster={Video_poster}
                    preload="none"
                >
                    <source
                        src={Video_webm} 
                        type="video/webm"
                    />
                    <source
                        src={Video_mp4} 
                        type="video/mp4"
                    />
                    Sorry, your browser doesn't support embedded videos.
                </video>
            </div>
        </Section> 
    )
}

export default VideoSection;