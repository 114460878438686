import React from "react";
import Section from "./section";
import TransformTemplate from "./transformTemplate";

const View = ({image, title, header, content, imageRight, children}) => {
    return(
        <Section>
            <div id={"view_" + title} className="flex justify-center items-center w-full max-w-screen-xl m-auto my-11">
                <div style={(imageRight) ? {flexDirection: "row-reverse"} : {} }className="flex justify-center items-center flex-wrap md:flex-nowrap md:my-20">
                    <div className="md:max-w-1/2 h-full md:my-10 ">
                        <img  alt="Illustration by undraw.co" src={image}></img>
                    </div>
                    <div className="md:max-w-1/2 h-full md:my-10 p-6 md:p-10">
                        <h2 style={{lineHeight: "1.25em"}} className="font-bold text-2xl lg:text-3xl text-left my-10 ">
                            <TransformTemplate
                                content={header}
                                formattingClassName={"color-ecoGreen"}
                            />
                        </h2>
                        {content &&
                            <p className="text-base lg:text-lg text-left md:text-left my-5">
                                <TransformTemplate
                                    content={content}
                                    formattingClassName={"font-bold"}
                                />
                            </p>
                        }
                        {!!children && children}
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default View;