import React from "react";
import Section from "./section"
import TransformTemplate from "./transformTemplate";

const EmissionCounter = ({image, content, imageRight, onInfoIconClick, kg}) => {
    return(
        <Section>
            <div id="view_counter" className="flex justify-center items-center w-full max-w-screen-xl m-auto">
                <div style={(imageRight) ? {flexDirection: "row-reverse"} : {} }className="flex justify-center items-center flex-wrap md:flex-nowrap md:my-20">
                    <div className="md:max-w-1/2 h-full md:my-10 p-6">
                        <img  alt="Illustration by undraw.co" src={image}></img>
                    </div>
                    <div className="md:max-w-1/2 h-full md:my-10 p-6">
                        <h2 style={{lineHeight: "1.25em"}} className="font-bold text-8xl md:text-6xl lg:text-9xl text-left my-10 ">
                            <span>{kg}</span>
                            <span className="text-7xl md:text-6xl lg:text-9xl"> kg</span>
                            </h2>
                        <div className="md:ml-16">
                            <p className="text-base text-2xl text-left md:text-left my-10">
                                    <TransformTemplate
                                        content={content}
                                        formattingClassName={"font-bold color-ecoGreen"} 
                                    />
                                <button title="Informationen zur Berechnung" className="cursor-pointer" onClick={onInfoIconClick}><sup className="hover:text-blue-400">&#9432;</sup></button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default EmissionCounter;